<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-row no-gutters>
        <v-col align="center" v-if="!isVisible">
          <p>У вас отсуствуют права на просмотр этой страницы</p>
        </v-col>
        <v-col v-else>
          <v-card class="pa-2 ma-2" outlined tile>
            <!-- toolbar -->
            <reference-tool-bar
              :title="options.title.main"
              :createItemTitle="options.title.create"
              :formTitle="formTitle"
              :editedIndex="editedIndex"
              :dialog="dialog"
              @save="save"
              @close="close"
              @open="open"
            >
              <template v-slot:formCreateEdit>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.name"
                    label="Роль"
                    :hint="`Допускаются латинские буквы, от 3 до 30 символов`"
                    :rules="[rules.required, rules.alfabetEn]"
                    autofocus
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="statusSelect"
                    :hint="`Идентификатор статуса - [${statusSelect.id}]`"
                    :items="statusList"
                    item-text="nameRu"
                    item-value="id"
                    label="Статус"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
              </template>
            </reference-tool-bar>
            <!--Таблица с данными-->
            <reference-table
              :headers="options.headers"
              :items="options.data"
              @edit-item="editItem($event)"
              @remove-item="removeItem($event)"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get, sync } from "vuex-pathify";
import { mapActions } from "vuex";
import { rules } from "@/util/rules";

export default Vue.extend({
  name: "Contact.vue",
  components: {
    ReferenceTable: () => import("@/components/reference/ReferenceTable.vue"),
    ReferenceToolBar: () =>
      import("@/components/reference/ReferenceToolBar.vue")
  },
  computed: {
    options: get("reference/optionsRole"),
    statusList: get("reference/getStatus"),
    formTitle() {
      return this.editedIndex === -1 ? "Создать" : "Редактировать";
    },
    isVisible: () =>
      JSON.parse(localStorage.getItem("user")).role.toLowerCase() == "admin"
        ? true
        : false
  },
  data() {
    return {
      rules,
      dialog: false,
      editedIndex: -1,
      statusSelect: 0
    };
  },
  methods: {
    ...mapActions({
      fetchReference: "reference/fetchRole",
      fetchStatus: "reference/fetchStatus"
    }),
    initialize() {
      this.fetchStatus().then(result => {
        this.statusSelect = this.statusList[0];
      });
      this.fetchReference();
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.options.editedItem = Object.assign({}, this.options.defaultItem);
        this.statusSelect = this.statusList[0];
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      const model = {
        statusId: this.statusSelect.id,
        id: this.options.editedItem.id,
        name: this.options.editedItem.name
      };
      if (this.editedIndex > -1) {
        this.$http.put(this.options.path.base, model).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      } else {
        model.id = ""; // Guid
        this.$http.post(this.options.path.base, model).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      }
      this.dialog = false;
    },
    editItem(item) {
      this.editedIndex = this.options.data.indexOf(item);
      this.options.editedItem = Object.assign({}, item);
      this.statusSelect = { id: item.status.id, name: item.status.NameRu };
      this.open();
    },
    removeItem(item) {
      if (confirm("Вы уверены что хотите удалить роль?")) {
        const model = Object.assign({}, item);
        this.$http
          .delete(this.options.path.base, { data: model })
          .then(response => {
            if (response.status === "Ok") this.initialize();
          });
      }
    }
  },
  created() {
    this.initialize();
  }
});
</script>
